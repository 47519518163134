@use "sass:math";

@use "../../../ui/vars" as *;

.document-toc {
  font-size: 1rem;
  margin-bottom: 2rem;
  padding: 0;
  position: relative;

  @media screen and (max-width: $screen-sm) {
    padding: 0 1rem;
  }

  // double classes to override specificity from root styles
  &-heading.document-toc-heading {
    font: var(--type-heading-h6);
    letter-spacing: 1.5px;
    margin: 0 0 1rem 0;
    text-transform: uppercase;
  }
  // double classes to override specificity from root styles
  &-list.document-toc-list {
    list-style: none;
    padding-left: 0;

    li {
      margin: 0;
    }
  }

  // double classes to override specificity from root styles
  &-link.document-toc-link:not(.button) {
    display: block;
    padding: 0.5rem 1rem;
    font: var(--type-body-m);
    border-left: 2px solid var(--border-secondary);
    color: var(--text-secondary);
    text-decoration: none;

    &:hover {
      color: var(--text-link);
    }

    &[aria-current] {
      color: var(--text-primary);
      font-weight: 600;
      border-left: 2px solid var(--text-link);
      background-color: var(--code-background-inline);
    }
  }

  .show-toc {
    display: block;
  }
}
