@use "../../ui/vars" as *;

.contributor-spotlight {
  background: var(--homepage-secondary-bg);
  color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 1rem;

  p {
    margin: 0;
  }

  h4 {
    font: var(--type-body-m);
  }

  .text-col {
    display: flex;
    flex-direction: column;
    max-width: 45rem;
    width: 100%;
    gap: 1rem;
    align-items: center;
    text-align: center;
  }

  .spotlight-cta {
    width: fit-content;
    --button-bg: #{$mdn-color-dark-grey-90};
    --button-border-color: #{$mdn-color-dark-grey-90};
    --button-color: #{$mdn-color-white};
    --button-radius: 10rem;
    --button-padding: 1rem;
    --button-primary-hover: #{$mdn-color-dark-grey-40};
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    max-width: 45rem;
    width: 100%;
    gap: 2rem;
    padding: 2rem 0;
    align-items: center;
  }

  .contributor-graphic {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    word-break: break-word;
    opacity: 0.5;
  }

  .contributor-name {
    // note: this deviates from var(--type-title-l),
    // as it uses the body font family.
    font-size: 1.5rem;
    font-weight: 650;
  }

  @media screen and (min-width: $screen-md) {
    padding: 2rem 0;

    .wrapper {
      flex-direction: row;
      align-items: initial;
    }

    .text-col {
      align-items: initial;
      text-align: left;
    }
  }
}
