@use "../ui/vars" as *;
@use "../ui/base/mdn" as *;

main.contribute {
  width: 100%;
  margin-bottom: 3rem;

  .stats-container {
    background-color: var(--background-primary);
    color: var(--text-primary);
    width: 100%;
    margin-bottom: 3rem;
  }
  section {
    max-width: 50rem;
    margin: 0 auto;

    &.stats-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 0.5rem 2rem;
    }

    h1 {
      margin-top: 8rem;
      font-size: 3rem;
      text-align: center;

      @include mify;
    }

    h2,
    h3 {
      padding: 0 1rem;
    }

    p {
      padding: 0 1rem;
    }

    blockquote {
      margin: 2rem 0;
      padding: 2rem;
      display: flex;
      flex-direction: row;
      gap: 2rem;
      border: none;

      @media screen and (max-width: 40rem) {
        flex-wrap: wrap-reverse;
      }

      &.owd {
        background-color: var(--text-primary);
        color: var(--background-primary);

        h4 {
          color: var(--text-primary);
          background-color: var(--background-primary);
        }
      }

      &.pab {
        background-color: var(--text-link);
        color: var(--background-primary);

        h4 {
          background-color: var(--text-primary);
        }
      }

      .partner {
        width: 100%;
        width: max-content;
        flex-direction: column;

        h4 {
          font-size: 2rem;
          display: inline;
          line-height: 1.4;
        }

        a {
          display: block;
        }
      }
    }

    .stats {
      display: grid;
      gap: 0.3em;

      @media screen and (max-width: $screen-md) {
        grid-template-columns: 1fr 1fr;
      }

      @media screen and (max-width: $screen-sm) {
        grid-template-columns: 1fr;
      }

      @media screen and (min-width: $screen-md) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
      li {
        display: flex;
        flex-direction: column;
        align-items: center;
        background: var(--text-link);
        padding: 0.5rem 2rem;
        color: var(--background-primary);

        .number {
          font-size: 3rem;
        }
        .legend {
          font-size: 0.8rem;
          max-width: 5rem;
          text-align: center;
        }
      }
    }
  }
}
