@use "sass:color";
@use "../../vars" as *;

.header-search {
  .search-widget {
    position: relative;
    width: 100%;

    @media screen and (min-width: $screen-md) {
      align-items: center;
      display: flex;
      gap: 0.5rem;
      margin: 0;
    }
  }

  .search-input-field {
    // make webkit play nice with search input types
    -webkit-appearance: none; /* stylelint-disable-line property-no-vendor-prefix */
    background-color: var(--background-primary);
    border: 1px solid var(--border-primary);
    border-radius: 100px;
    color: var(--text-primary);
    height: var(--form-elem-height);
    min-width: 60px;
    padding-left: 2rem;
    width: 100%;

    &:focus {
      outline: 0 none;
      box-shadow: var(--focus-01);
      border-color: var(--field-focus-border);
    }

    &::placeholder {
      font: var(--type-body-l);
      text-decoration: underline;
      text-decoration-thickness: 0.15rem;
      text-underline-offset: 0.1em;
      text-decoration-color: var(--text-link);
      opacity: 1;
    }
  }

  .server-error {
    color: var(--icon-critical);
    margin: 0;
  }

  .search-results {
    color: var(--text-primary);
    border: 1px solid var(--border-secondary);
    border-radius: var(--elem-radius);
    box-shadow: var(--shadow-01);
    left: 0;
    position: absolute;
    top: 42px;
    width: 100%;
    z-index: 1000;

    .indexing-warning {
      color: var(--icon-warning);
    }

    div {
      padding: 0.5rem;
    }

    .nothing-found {
      font-style: italic;
    }

    .fuzzy-engaged {
      font-size: 0.8125rem;
    }

    .result-item {
      border-bottom: 1px solid var(--border-secondary);
      word-break: break-word;
      background: var(--background-secondary);
      font: var(--type-body-m);

      &:first-child {
        border-radius: var(--elem-radius) var(--elem-radius) 0 0;
      }

      &:last-child {
        border-radius: 0 0 var(--elem-radius) var(--elem-radius);
      }
    }

    .result-item:hover,
    .result-item.highlight {
      background-color: var(--border-secondary);
    }

    .result-item:hover,
    .result-item:focus {
      cursor: pointer;
    }

    div:last-child {
      border-bottom: 0;
    }

    mark {
      background: #{color.adjust($mdn-color-yellow-30, $alpha: -0.6)};
    }

    small {
      overflow-wrap: break-word;
    }

    a {
      text-overflow: ellipsis;
    }

    div.searchindex-error {
      color: var(--icon-critical);
    }
  }
}

.search-form {
  position: relative;
}

.button.search-button {
  --button-color: var(--icon-secondary);
  --button-height: 28px;
  --button-padding: 0;
  position: absolute;
  top: 2px;
  left: 2px;
  width: 28px;

  &:hover {
    background: transparent;
  }
}

.close-search-button {
  display: none;

  @media screen and (min-width: $screen-md) {
    display: block;
  }
}
