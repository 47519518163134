@use "../../ui/vars" as *;

.icon-card-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.icon-card {
  border: 1px solid var(--border-secondary);
  border-radius: var(--elem-radius);
  background-color: var(--background-primary);
  padding: 1rem;
  transition: 0.3s ease-out; // this removes a flash from .doomed animations

  a {
    &:link,
    &:visited {
      color: var(--text-primary);
    }
  }

  & > *:last-child {
    margin-bottom: 0;
  }

  &-icon {
    width: 3rem;
    height: 3rem;
    background-color: var(--http-accent-color);
    border-radius: 0.125rem;
    color: var(--background-primary);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75rem;
    font-style: italic;
    padding: 0.5rem;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &.html {
      background-color: var(--html-accent-color);
    }

    &.css {
      background-color: var(--css-accent-color);
    }

    &.javascript {
      background-color: var(--js-accent-color);
    }

    &.http {
      background-color: var(--http-accent-color);
    }

    &.apis {
      background-color: var(--apis-accent-color);
    }
  }

  &-title-wrap {
    display: flex;
    gap: 1rem;

    .dropdown {
      align-self: center;
    }

    .breadcrumbs a:link,
    .breadcrumbs a:visited {
      color: var(--text-secondary);
    }
  }

  &-title {
    font: var(--type-emphasis-l);
    margin: 0;
  }

  &-description {
    font: var(--type-body-m);
    color: var(--text-secondary);
  }

  &-actions {
    display: flex;
    gap: 0.5rem;
  }

  .icon-card-content {
    flex: 1 1 80%;
    overflow: hidden;

    @media screen and (max-width: $screen-sm) {
      flex-basis: 95%;
    }

    h4 {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .icon-card-actions {
    margin-left: auto;
    align-self: center;
  }

  .breadcrumbs {
    list-style: none;
    margin-bottom: 0;
    font: var(--type-body-m);
    color: var(--text-secondary);
    margin-bottom: 0.5rem;

    li {
      display: inline-block;
      margin: 0;

      &::after {
        background-color: var(--text-secondary);
        content: "";
        display: inline-block;
        height: 8px;
        margin: 0 4px;
        mask-image: url("~@mdn/dinocons/arrows/chevron.svg");
        transform: rotate(-90deg);
        vertical-align: middle;
        width: 8px;
      }

      &:last-child:after {
        content: unset;
      }
    }
  }
}
