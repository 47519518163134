@use "../../ui/vars" as *;

.testimonial {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem 0;

  @media screen and (min-width: $screen-md) {
    flex-direction: row;
  }

  iframe {
    height: 50vw;
    padding: 0;

    @media screen and (min-width: $screen-md) {
      width: 48%;
      height: initial;
    }
  }

  .testimonial-copy {
    max-width: 18rem;
    text-align: center;
    margin: 0 auto;

    @media screen and (min-width: $screen-md) {
      margin: initial;
      text-align: initial;
    }

    img {
      margin: 0 auto;

      @media screen and (min-width: $screen-md) {
        margin: initial;
      }
    }

    p {
      font-size: 18px;
      line-height: 120%;
    }

    .author-name,
    .author-title {
      /* MDN UI / Body / M */
      font-size: 13px;
      line-height: 1.2;
      display: block;
    }
  }

  .quotation-mark {
    width: 10px;
    height: 10px;
    margin-left: 4px;
    background-color: var(--icon-primary);
    mask-image: url("./quote.svg");
    mask-size: cover;
  }
}
