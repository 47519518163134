@use "../../../ui/vars" as *;

.main-page-content .metadata {
  background-color: var(--background-secondary);
  margin: 2rem 0;
  padding: 1rem;
  border: 1px solid var(--border-primary);
  box-shadow: var(--shadow-01);
  border-radius: var(--elem-radius);

  h4 {
    padding: 0;
  }
}

.metadata-content-container {
  margin: 0 auto;
  max-width: 1440px;
  width: 100%;

  .last-modified-date {
    margin-bottom: 0;
  }
}
