@use "../../vars" as *;

.logo {
  display: flex;
  align-items: center;
  width: 10rem;

  svg {
    margin-top: 0.3rem;
    height: 1.5rem;
  }
}

/* DARK AND HIGH CONTRACT BLACK MODE */
.dark,
.high-contrast-black {
  .logo-text {
    fill: white;
  }
}
