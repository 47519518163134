.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
  color: var(--code-token-comment);
}

.token.punctuation {
  color: var(--code-token-punctuation);
}

.token.attr-name,
.token.builtin,
.token.char,
.token.inserted,
.token.selector,
.token.string,
.token.property,
.token.function {
  color: var(--code-token-attribute-name);
}

.token.atrule,
.token.attr-value {
  color: var(--code-token-attribute-value);
}

.token.keyword {
  color: var(--code-token-tag);
}

.token.tag,
.token.boolean,
.token.number,
.token.constant,
.token.symbol,
.token.deleted {
  color: var(--code-token-tag);
}

.token.selector,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
  color: var(--code-token-selector);
}

.token.important,
.token.bold {
  font-weight: bold;
}

.token.italic {
  font-style: italic;
}

.token.entity {
  cursor: help;
}
