@use "../../vars" as *;

.page-wrapper {
  grid-template-columns: 100%;
}

.standard-page {
  max-width: inherit;

  .page-content-container {
    display: grid;
    grid-template-columns: 100%;
  }

  &.plus {
    background-color: var(--background-secondary);
  }

  @media screen and (min-width: $screen-md) {
    .page-content-container {
      margin: 0 auto;
      max-width: var(--max-width);
      width: 100%;

      &.fullwidth {
        max-width: initial;
      }
    }
  }
}
