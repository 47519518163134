@use "../../../ui/vars" as *;

.mandala-container {
  display: flex;
  justify-content: center;
  // transform: skew(-7deg, 10deg);

  --mandala-primary: #{$mdn-color-marketing-grey-70};
  --mandala-accent-1: #{$mdn-color-red-20};
  --mandala-accent-2: #{$mdn-color-green-20};
  --mandala-accent-3: #{$mdn-color-yellow-20};
  --mandala-accent-4: #{$mdn-color-marketing-grey-30};

  svg {
    font-size: 1.5rem;
    font-weight: 100;
  }

  svg > text {
    fill: var(--mandala-primary);
  }

  .mandala-accent-1 {
    font-size: 1.5rem;
  }

  .mandala-accent-2 {
    font-size: 1.3rem;
  }

  .mandala-accent-3 {
    font-size: 1.2rem;
  }

  .mandala-accent-4 {
    font-size: 1.1rem;
  }

  .mandala-accent-5 {
    font-size: 1rem;
  }

  &.animate {
    svg > text > textPath > tspan {
      animation: mandala-color-change 50s infinite;
      animation-timing-function: ease-in-out;
    }

    svg > text > textPath > tspan {
      fill: var(--mandala-primary);
    }

    .mandala-accent-1 > textPath > tspan {
      fill: var(--mandala-accent-1);
      animation-delay: -15s;
    }

    .mandala-accent-2 > textPath > tspan {
      fill: var(--mandala-accent-2);
      animation-delay: -20s;
    }

    .mandala-accent-3 > textPath > tspan {
      fill: var(--mandala-accent-3);
      animation-delay: -30s;
    }

    .mandala-accent-5 > textPath > tspan {
      fill: var(--mandala-accent-4);
      animation-delay: -40s;
    }
  }
  @keyframes mandala-color-change {
    0% {
      fill: var(--mandala-primary);
    }

    10% {
      fill: var(--mandala-primary);
    }

    15% {
      fill: var(--mandala-accent-1);
    }

    20% {
      fill: var(--mandala-primary);
    }

    25% {
      fill: var(--mandala-primary);
    }

    30% {
      fill: var(--mandala-accent-2);
    }

    35% {
      fill: var(--mandala-primary);
    }

    40% {
      fill: var(--mandala-primary);
    }

    50% {
      fill: var(--mandala-accent-3);
    }

    55% {
      fill: var(--mandala-primary);
    }

    60% {
      fill: var(--mandala-primary);
    }

    65% {
      fill: var(--mandala-accent-4);
    }

    70% {
      fill: var(--mandala-primary);
    }

    100% {
      fill: var(--mandala-primary);
    }
  }
}
