@use "../ui/vars" as *;

.about {
  width: 100%;

  .about-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 36rem;
    margin: 0 auto;
    padding: 0 1rem 2rem 1rem;

    @media screen and (min-width: $screen-md) {
      padding: 0;
      padding-bottom: 2rem;
    }

    h1 {
      margin-top: 2rem;
      font-size: 4rem;
      text-align: center;
    }

    header {
      display: flex;
      gap: 1rem;
      flex-direction: column;
      align-items: center;
      padding: 2rem 0;

      .headline {
        font-family: var(--font-heading);
        font-style: normal;
        font-weight: normal;
        font-size: 1.313rem;
        line-height: 175%;
        text-align: center;
        margin: 0;
      }
    }

    p {
      font-weight: 350;
      font-size: 1rem;
      line-height: 175%;
    }

    h2 {
      font-size: 1.3rem;
      text-align: center;
    }

    .heading-break {
      display: none;
      @media screen and (min-width: $screen-md) {
        display: block;
      }
    }
  }
}
