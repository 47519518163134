@use "sass:color";
@use "../../vars" as *;
@use "../../base/typography" as *;

.notifications-menu {
  align-items: center;
  display: flex;
  position: relative;

  .top-level-entry {
    position: relative;
  }

  @media screen and (min-width: $screen-md) {
    .top-navigation-main & .top-level-entry,
    .notifications-label {
      position: absolute !important;
      font: 0/0 a;
      padding: 0 !important;
      margin: 0 !important;
      height: 0;
      color: transparent;
    }
  }
}

.notifications-button {
  &,
  .icon {
    display: none;
  }

  @media screen and (max-width: $screen-md) {
    .button-wrap {
      justify-content: flex-start;
      text-transform: initial;
    }
  }

  @media screen and (min-width: $screen-md) {
    &,
    .icon {
      display: inline-block;
    }
  }
}

.notifications-count-container {
  align-items: center;
  background-color: var(--icon-secondary);
  border-radius: 50%;
  color: white;
  display: inline-flex;
  font: var(--type-body-s);
  height: 1.5em;
  justify-content: center;
  line-height: 1;
  margin-left: 0.25rem;
  padding: 2px;
  position: relative;
  text-align: center;
  top: -1px;
  width: 1.5em;

  @media screen and (min-width: $screen-md) {
    display: none;

    // The small dot that appears over the icon
    @at-root .notifications-button &.has-unread {
      background-color: var(--icon-information);
      content: "";
      display: block;
      height: 8px;
      position: absolute;
      right: 7px;
      top: 7px;
      width: 8px;
      border: 1px solid #fff;
      text-indent: -9999rem;
    }
  }
}

.my-notifications {
  display: none;
  margin: 0;
  padding: 0;

  li {
    list-style: none;
  }

  .notifications-submenu-action {
    border-top: 1px solid var(--border-primary);
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1rem;

    &.unread {
      background-color: var(--accent-tertiary);
    }

    &:hover,
    &:focus {
      background-color: var(--background-secondary);
      text-decoration: none;
    }
  }

  li:last-child .notifications-submenu-action {
    border-radius: 0 0 0.25rem 0.25rem;
  }

  .notifications-submenu-header,
  .notifications-submenu-item-heading,
  .notifications-submenu-item-description {
    font-size: 0.8125rem;
  }

  .notifications-submenu-header {
    display: flex;
    gap: 0.5rem;
    justify-content: space-between;
    padding: 1rem;
  }

  .notifications-submenu-header-action {
    padding: 0;
  }

  .notifications-submenu-item-heading,
  .notifications-submenu-item-description {
    color: var(--text-primary);
  }

  .notifications-submenu-item-heading {
    font-weight: var(--font-body-strong-weight);
  }

  .notifications-submenu-item-description {
    margin: 0;
  }

  .notifications-submenu-item-created {
    color: var(--text-inactive);
    font-size: 0.6875rem;
    font-weight: 600;
  }

  .notifications-submenu-empty-message {
    padding: 0.5rem 1rem;
    text-align: center;

    p {
      font: var(--type-body-m);
      color: var(--text-secondary);
      margin: 0.5rem 0;
    }
  }

  @media screen and (min-width: $screen-md) {
    &.show {
      background-color: var(--background-primary);
      border: 1px solid var(--border-primary);
      border-radius: var(--elem-radius);
      box-shadow: var(--shadow-01);

      display: block;
      left: initial;
      min-width: 310px;
      padding: 0;
      position: absolute;
      right: 0;
      top: 100%;
      width: max-content;
      z-index: 1000;
    }
  }
}
