@use "../../vars" as *;

.breadcrumbs-container {
  display: flex;
  align-items: center;
  margin-right: auto;

  ol {
    line-height: 1.2;
  }

  li {
    display: none;
    hyphens: auto;

    // only show first and last on mobile
    &:first-child,
    &:last-child {
      display: inline-flex;
    }

    .breadcrumb {
      &::after {
        margin-right: 0.5rem;
        background-color: var(--icon-secondary);
        content: "";
        display: block;
        height: 12px;
        mask-image: url("../../../assets/icons/chevron.svg");
        mask-size: 12px;
        transform: rotate(-90deg);
        width: 12px;
      }
    }

    a {
      align-items: center;
      display: flex;

      &:link,
      &:visited {
        color: var(--text-secondary);
      }

      &:focus-visible {
        outline: 0;

        [property="name"] {
          outline-style: auto;
          outline-color: var(--accent-primary);
          outline-offset: 1px;
        }
      }
    }

    [property="name"] {
      display: inline-block;
      margin-right: 0.5rem;
    }
  }

  @media screen and (min-width: $screen-lg) {
    li {
      display: inline-flex;
    }
  }
}
