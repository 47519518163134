@use "../ui/vars" as *;

.homepage {
  display: flex;
  gap: 2rem;
  flex-direction: column;
  align-items: center;

  width: 100vw;
  overflow-x: clip;
  overflow-y: visible;
  position: relative;

  p {
    font: var(--type-body-m);
  }

  .mdn-ui-emphasis-l {
    font: var(--type-emphasis-l);
    margin: 0;
  }

  .featured-articles {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0 1rem;
    align-items: flex-start;
  }

  .tile-container {
    display: flex;
    flex-direction: column;
    max-width: 45rem;
    gap: 1rem;
  }

  .article-tile {
    position: relative;
    border-radius: 0.5rem;
    border: 1px solid var(--border-primary);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    &:hover {
      box-shadow: var(--shadow-02);
    }

    .tile-tag {
      font: var(--type-emphasis-m);
      color: var(--text-primary);

      &:hover {
        text-decoration: underline;
      }
    }

    .tile-title {
      font: var(--type-title-l);
      color: var(--text-primary);
      font-family: var(--font-body);
      margin-bottom: 0.25rem;
    }

    p {
      font: var(--type-body-m);
      margin: 0;
    }
  }

  @media screen and (min-width: $screen-md) {
    .mdn-ui-emphasis-l {
      text-align: initial;
    }

    .featured-articles {
      align-items: revert;
      padding: 0;
    }

    .tile-container {
      flex-direction: row;
      padding: 0;
    }

    .article-tile {
      width: 33%;
    }
  }
}
