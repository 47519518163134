@use "../../ui/vars" as *;

.get-involved {
  background-image: radial-gradient(
    111.78% 55.34% at 16.18% 1.51%,
    #3fe1b0 -14%,
    #33b3fc 20.93%,
    #9752fa 59.32%,
    #ff9e11 161.2%,
    #ffe600 100%
  );
  background-size: 100% 446%;

  span {
    color: white;
    max-width: 36rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 0 auto;
    text-align: center;
    line-height: 1.75;

    padding: 2rem 1rem;

    @media screen and (min-width: $screen-md) {
      padding: 2rem 0;
    }

    h2 {
      font-family: var(--font-heading);
      font-weight: 600;
      font-size: 1.3rem;
      line-height: 120%;
      text-align: center;
      margin: 0;
    }

    .get-involved-cta {
      .button-wrap {
        background: black;
        color: white;
        border-radius: 10rem;
        width: fit-content;
        padding: 0.5rem 1rem;
      }
    }
  }
}
