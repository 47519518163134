@use "sass:color";
@use "../../vars" as *;

.html-link-container {
  a:hover,
  a:focus {
    .submenu-icon {
      &.html {
        background: var(--html-accent-color) !important;
      }
    }
  }
}

.css-link-container {
  a:hover,
  a:focus {
    .submenu-icon {
      &.css {
        background-color: var(--css-accent-color) !important;
      }
    }
  }
}

.javascript-link-container {
  a:hover,
  a:focus {
    .submenu-icon {
      &.javascript {
        background-color: var(--js-accent-color) !important;
      }
    }
  }
}

.http-link-container {
  a:hover,
  a:focus {
    .submenu-icon {
      &.http {
        background-color: var(--http-accent-color) !important;
      }
    }
  }
}

.apis-link-container {
  a:hover,
  a:focus {
    .submenu-icon {
      &.apis {
        background-color: var(--apis-accent-color) !important;
      }
    }
  }
}

.submenu-icon {
  &.html {
    background-color: var(--html-accent-engage);
  }

  &.css {
    background-color: var(--css-accent-engage);
  }

  &.javascript {
    background-color: var(--js-accent-engage);
  }

  &.http {
    background-color: var(--http-accent-engage);
  }

  &.apis {
    background-color: var(--apis-accent-engage);
  }
}
