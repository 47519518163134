$mdn-color-light-grey-05: #fbfbfe;
$mdn-color-light-grey-10: #f9f9fb;
$mdn-color-light-grey-20: #f0f0f4;
$mdn-color-light-grey-30: #e0e0e6;
$mdn-color-light-grey-40: #cfcfd8;
$mdn-color-light-grey-50: #bfbfc9;
$mdn-color-light-grey-60: #afafba;
$mdn-color-light-grey-70: #9f9fad;
$mdn-color-light-grey-80: #8f8f9d;
$mdn-color-light-grey-90: #80808e;
$mdn-color-dark-grey-05: #5b5b66;
$mdn-color-dark-grey-10: #52525e;
$mdn-color-dark-grey-20: #4a4a55;
$mdn-color-dark-grey-30: #42414d;
$mdn-color-dark-grey-40: #3a3944;
$mdn-color-dark-grey-50: #32313c;
$mdn-color-dark-grey-60: #2b2a33;
$mdn-color-dark-grey-70: #23222b;
$mdn-color-dark-grey-80: #1c1b22;
$mdn-color-dark-grey-90: #15141a;
$mdn-color-blue-05: #aaf2ff;
$mdn-color-blue-10: #80ebff;
$mdn-color-blue-20: #00ddff;
$mdn-color-blue-30: #00b3f4;
$mdn-color-blue-40: #0090ed;
$mdn-color-blue-50: #0060df;
$mdn-color-blue-60: #0250bb;
$mdn-color-blue-70: #054096;
$mdn-color-blue-80: #073072;
$mdn-color-blue-90: #09204d;
$mdn-color-green-05: #e3fff3;
$mdn-color-green-10: #d1ffee;
$mdn-color-green-20: #b3ffe3;
$mdn-color-green-30: #87ffd1;
$mdn-color-green-40: #54ffbd;
$mdn-color-green-50: #3fe1b0;
$mdn-color-green-60: #2ac3a2;
$mdn-color-green-70: #008787;
$mdn-color-green-80: #005e5e;
$mdn-color-green-90: #08403f;
$mdn-color-yellow-05: #ffffcc;
$mdn-color-yellow-10: #ffff98;
$mdn-color-yellow-20: #ffea80;
$mdn-color-yellow-30: #ffd567;
$mdn-color-yellow-40: #ffbd4f;
$mdn-color-yellow-50: #ffa436;
$mdn-color-yellow-60: #e27f2e;
$mdn-color-yellow-70: #c45a27;
$mdn-color-yellow-80: #a7341f;
$mdn-color-yellow-90: #960e18;
$mdn-color-red-05: #ffdfe7;
$mdn-color-red-10: #ffbdc5;
$mdn-color-red-20: #ff9aa2;
$mdn-color-red-30: #ff848b;
$mdn-color-red-40: #ff6a75;
$mdn-color-red-50: #ff4f5e;
$mdn-color-red-60: #e22850;
$mdn-color-red-70: #c50042;
$mdn-color-red-80: #810220;
$mdn-color-red-90: #440306;
$mdn-color-violet-05: #e7dfff;
$mdn-color-violet-10: #d9bfff;
$mdn-color-violet-20: #cb9eff;
$mdn-color-violet-30: #c688ff;
$mdn-color-violet-40: #ab71ff;
$mdn-color-violet-50: #9059ff;
$mdn-color-violet-60: #7542e4;
$mdn-color-violet-70: #592acb;
$mdn-color-violet-80: #45278d;
$mdn-color-violet-90: #321c64;
$mdn-color-pink-05: #ffdff0;
$mdn-color-pink-10: #ffb4db;
$mdn-color-pink-20: #ff8ac5;
$mdn-color-pink-30: #ff6bba;
$mdn-color-pink-40: #ff4aa2;
$mdn-color-pink-50: #ff298a;
$mdn-color-pink-60: #e21587;
$mdn-color-pink-70: #c60084;
$mdn-color-pink-80: #7f145b;
$mdn-color-pink-90: #50134b;
$mdn-color-ink-05: #393473;
$mdn-color-ink-10: #342f6d;
$mdn-color-ink-20: #312a64;
$mdn-color-ink-30: #2e255d;
$mdn-color-ink-40: #2b2156;
$mdn-color-ink-50: #291d4f;
$mdn-color-ink-60: #271948;
$mdn-color-ink-70: #20123a;
$mdn-color-ink-80: #20133a;
$mdn-color-ink-90: #1d1133;
$mdn-color-marketing-grey-05: #f9f9fa;
$mdn-color-marketing-grey-10: #ededf0;
$mdn-color-marketing-grey-20: #cdcdd4;
$mdn-color-marketing-grey-30: #b1b1bc;
$mdn-color-marketing-grey-40: #9595a2;
$mdn-color-marketing-grey-50: #7a7a8b;
$mdn-color-marketing-grey-60: #5e5e72;
$mdn-color-marketing-grey-70: #42435a;
$mdn-color-marketing-grey-80: #1f2033;
$mdn-color-marketing-grey-90: #141422;
$mdn-color-black: #000000;
$mdn-color-white: #ffffff;

$mdn-theme-light-text-primary: #15141a;
$mdn-theme-light-text-secondary: #5b5b66;
$mdn-theme-light-text-inactive: #5b5b66a6;
$mdn-theme-light-text-link: #0090ed;
$mdn-theme-light-background-primary: #ffffff;
$mdn-theme-light-background-secondary: #f9f9fb;
$mdn-theme-light-background-tertiary: #fbfbfe;
$mdn-theme-light-border-primary: #cfcfd8;
$mdn-theme-light-border-secondary: #e0e0e6;
$mdn-theme-light-button-primary-default: #15141a;
$mdn-theme-light-button-primary-hover: #3a3944;
$mdn-theme-light-button-primary-active: #5b5b66;
$mdn-theme-light-button-primary-inactive: #15141a;
$mdn-theme-light-button-secondary-default: #fbfbfe;
$mdn-theme-light-button-secondary-hover: #e0e0e6;
$mdn-theme-light-button-secondary-active: #e0e0e6;
$mdn-theme-light-button-secondary-inactive: #fbfbfe;
$mdn-theme-light-button-secondary-border-focus: #0090ed;
$mdn-theme-light-icon-primary: #5b5b66;
$mdn-theme-light-icon-secondary: #afafba;
$mdn-theme-light-icon-information: #0090ed;
$mdn-theme-light-icon-warning: #ffa436;
$mdn-theme-light-icon-critical: #e22850;
$mdn-theme-light-icon-success: #2ac3a2;
$mdn-theme-light-accent-primary: #0090ed;
$mdn-theme-light-accent-secondary: #0090ed;
$mdn-theme-light-field-focus-border: #0090ed;
$mdn-theme-light-focus-01: 0px 0px 0px 3px rgba(0, 144, 237, 0.4);
$mdn-theme-light-shadow-01: 0px 1px 2px rgba(43, 42, 51, 0.05);
$mdn-theme-light-shadow-02: 0px 1px 6px rgba(43, 42, 51, 0.1);

$mdn-theme-light-code-token-tag: #e22850;
$mdn-theme-light-code-token-punctuation: #7f145b;
$mdn-theme-light-code-token-attribute-name: #e27f2e;
$mdn-theme-light-code-token-attribute-value: #008787;
$mdn-theme-light-code-token-comment: #5b5b66;
$mdn-theme-light-code-token-default: #15141a;
$mdn-theme-light-code-token-selector: #054096;
$mdn-theme-light-code-background-inline: #f0f0f4;
$mdn-theme-light-code-background-block: rgba(240, 240, 244, 0.5);

$mdn-theme-dark-text-primary: #fbfbfe;
$mdn-theme-dark-text-secondary: #cfcfd8;
$mdn-theme-dark-text-inactive: #cfcfd8a6;
$mdn-theme-dark-text-link: #3fe1b0;
$mdn-theme-dark-background-primary: #15141a;
$mdn-theme-dark-background-secondary: #23222b;
$mdn-theme-dark-background-tertiary: #2b2a33;
$mdn-theme-dark-border-primary: #52525e;
$mdn-theme-dark-border-secondary: #3a3944;
$mdn-theme-dark-button-primary-default: #fbfbfe;
$mdn-theme-dark-button-primary-hover: #cfcfd8;
$mdn-theme-dark-button-primary-active: #9f9fad;
$mdn-theme-dark-button-primary-inactive: #fbfbfe;
$mdn-theme-dark-button-secondary-default: #2b2a33;
$mdn-theme-dark-button-secondary-hover: #52525e;
$mdn-theme-dark-button-secondary-active: #5b5b66;
$mdn-theme-dark-button-secondary-inactive: #2b2a33;
$mdn-theme-dark-shadow-01: 0px 1px 2px rgba(251, 251, 254, 0.2);
$mdn-theme-dark-shadow-02: 0px 1px 6px rgba(251, 251, 254, 0.2);
$mdn-theme-dark-icon-primary: #fbfbfe;
$mdn-theme-dark-icon-secondary: #afafba;
$mdn-theme-dark-icon-information: #0bb0f1;
$mdn-theme-dark-icon-warning: #ffa436;
$mdn-theme-dark-icon-critical: #ff4f5e;
$mdn-theme-dark-icon-success: #3fe1b0;
$mdn-theme-dark-accent-primary: #0090ed;
$mdn-theme-dark-accent-secondary: #0090ed;
$mdn-theme-dark-code-token-tag: #ff4f5e;
$mdn-theme-dark-code-token-punctuation: #8f8f9d;
$mdn-theme-dark-code-token-attribute-name: #ffa436;
$mdn-theme-dark-code-token-attribute-value: #3fe1b0;
$mdn-theme-dark-code-token-comment: #8f8f9d;
$mdn-theme-dark-code-token-default: #fbfbfe;
$mdn-theme-dark-code-token-selector: #0bb0f1;
$mdn-theme-dark-code-background-inline: #3a3944;
$mdn-theme-dark-code-background-block: rgba(58, 57, 68, 0.5);

$screen-sm: 426px;
$screen-md: 769px;
$screen-lg: 1200px;
$screen-xl: 1441px;

/*
 * z-index scale
 */
$send-to-back: -1;
$bring-to-front: 9999;
$bottom-layer: 100;
$middle-layer: 200;
$top-layer: 300;
