@use "../../../ui/vars" as *;

.language-menu {
  .submenu-item {
    padding: 0.5rem;
  }

  @media (min-width: $screen-md) {
    right: 0;
  }
}
