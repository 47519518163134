@use "../../vars" as *;
@use "../../base/typography" as *;

.submenu {
  background-color: var(--background-primary);
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    width: 100%;
  }

  a,
  .submenu-item {
    align-items: center;
    border: 1px solid transparent;
    color: var(--text-primary);
    display: flex;
    gap: 0.75rem;
    height: 100%;
    padding: 0.5rem;
    width: 100%;

    li:last-child &:not(:focus) {
      border-bottom-color: transparent;
    }

    &:hover {
      background-color: var(--border-secondary);
      text-decoration: none;
    }

    @at-root .main-menu &:hover {
      background-color: var(--background-secondary);
    }

    &:focus {
      border: 1px solid var(--button-secondary-border-focus);
      box-shadow: var(--button-focus-effect);
    }
  }

  @media screen and (min-width: $screen-md) {
    background-color: var(--background-secondary);
    border-radius: var(--elem-radius);
    border: 1px solid var(--border-primary);
    box-shadow: var(--shadow-02);
    max-width: 25rem;
    padding: 0.5rem;
    position: absolute;
    top: calc(100% + 4px);
    width: max-content;
    z-index: var(--z-index-mid);

    @at-root .main-menu & {
      background-color: var(--background-primary);
    }

    .submenu-item {
      border-bottom: 0;
      border-radius: var(--elem-radius);
      padding: 0.75rem;
    }

    /*
      Creates a space between the top-level menu and the submenu that
      keeps the menu open when the cursor moves to the menu
    */
    &:before {
      content: "";
      display: block;
      height: 8px;
      left: 0;
      position: absolute;
      top: -8px;
      width: 100%;
    }
  }
}

.submenu-header {
  display: block;
  padding: 1rem;

  .submenu-item {
    padding: 0;
  }

  .submenu-item:hover {
    background: none;
  }

  @media screen and (min-width: $screen-md) {
    border-bottom: 1px solid var(--border-primary);
  }
}

.submenu-icon {
  background-color: var(--icon-primary);
  height: 2rem;
  position: relative;
  width: 0.2rem;
}

.submenu-item-heading,
.submenu-item {
  font: var(--type-body-m);

  @media screen and (min-width: $screen-md) {
    // If there is sub-text associated with this heading, make it bold.
    &:not(:only-child) {
      font: var(--type-emphasis-m);
    }
  }
}

.submenu-item-description {
  display: none;

  @media screen and (min-width: $screen-md) {
    display: block;
    margin: 0.25rem 0 0 0;
    font: var(--type-body-s);
  }
}

.submenu-item-subtext {
  font: var(--type-emphasis-m);
}

.active-menu-item {
  background-color: var(--background-primary);
  border-color: var(--border-secondary);
}
