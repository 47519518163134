@use "../../vars" as *;
.signout-form {
  padding: 0.5rem;

  @media screen and (min-width: $screen-md) {
    border-top: 1px solid var(--border-primary);
  }
}

button.signout-button {
  width: 100%;

  &:hover,
  &:focus {
    background-color: var(--background-secondary);
  }
}
